import { Injectable } from '@angular/core';
import { EditTaxiRequestBody, RegisterTaxiRequestBody, TableDefaults, TableRequestBody } from 'src/app/models/local';
import { Extra, Taxi, TaxiOwner } from 'src/app/models/remote';
import { environment } from 'src/environments/environment';

const TaxisStore = Backendless.Data.of('TaxiCars');

@Injectable({
  providedIn: 'root'
})
export class TaxisService {

  constructor() {
  }

  public getTaxisForManage(tableRequestBody: TableRequestBody = {
    pageOffset: 0,
    pageSize: TableDefaults.PageSize
  }): Promise<{ taxis: Taxi[], totalRows: number }> {
    return Backendless.CustomServices.invoke('AdminService', 'getTaxisForManage', tableRequestBody);
  }

  public getTaxiById(taxiId: string): Promise<Taxi> {
    return Backendless.CustomServices.invoke('AdminService', 'getTaxiById', taxiId);
  }

  public editTaxiPicture(taxiId: string, pictureFile: File): Promise<Taxi> {
    let taxi;
    return TaxisStore.findById(taxiId)
      .then((foundTaxi) => {
        taxi = foundTaxi;
        Backendless.Files.remove(taxi.picture);

        const path = `${environment.backendless.PICTURES_PATH.TAXIS}/${taxi.objectId}`;
        return Backendless.Files.upload(pictureFile, path, true);
      })
      .then((response: any) => {
        taxi.picture = response.fileURL;
        return TaxisStore.save(taxi);
      })
      .then(() => {
        const whereClause = `objectId = '${taxiId}'`;

        const queryBuilder = Backendless.DataQueryBuilder.create();
        queryBuilder
            .setWhereClause(whereClause)
            .setRelated(['driver',
                         'taxiOwner',
                         'taxiConcessionaire',
                         'acceptedExtras']);

        return TaxisStore.find(queryBuilder)
      })
      .then((taxiFound: Taxi[]) => {
        return taxiFound[0];
      })
  }

  public createTaxi(requestBody: RegisterTaxiRequestBody): Promise <Taxi> {

    const taxi = {
      brand: requestBody.brand,
      insuranceCompany: requestBody.insuranceCompany,
      concessionNumber: requestBody.concessionNumber,
      insuranceValidity: requestBody.insuranceValidity,
      model: requestBody.model,
      year: requestBody.year,
      plate: requestBody.plate,
      policyNumber: requestBody
    }

    let taxiStored;
    return TaxisStore.save(taxi).then(persistedTaxi => {
      taxiStored = persistedTaxi;
      return TaxisStore.setRelation(taxiStored, 'taxiOwner', [requestBody.taxiOwnerId]);
    })
    .then(() => {
      return TaxisStore.setRelation(taxiStored, 'taxiConcessionaire', [requestBody.taxiConcessionaireId]);
    })
    .then(() => {
      const path = `${environment.backendless.PICTURES_PATH.TAXIS}/${taxiStored.objectId}`;

      let picturePromises = [
        Backendless.Files.upload(requestBody.picture, path, true),
        Backendless.Files.upload(requestBody.insuranceFile, path, true)
      ];

        return Promise.all(picturePromises);
    })
    .then((responses: any) => {
      taxiStored.picture = responses[0].fileURL;
      taxiStored.insuranceFile = responses[1].fileURL;
      return TaxisStore.save(taxiStored);
    })
    .then(() => {
      const serviceObjectIds = requestBody.additionalServices.map(service => ({objectId: service}))
      return TaxisStore.setRelation(taxiStored, 'acceptedExtras', serviceObjectIds);
    })
    .then(() => {
      const whereClause = `objectId = '${taxiStored.objectId}'`;

      const queryBuilder = Backendless.DataQueryBuilder.create();
      queryBuilder
          .setWhereClause(whereClause)
          .setRelated(['driver',
                       'taxiOwner',
                       'taxiConcessionaire']);

      return TaxisStore.find(queryBuilder)
    })
    .then((taxiFound: Taxi[]) => {
      return taxiFound[0];
    });
  }

  public editTaxiInsuranceFile(taxiId: string, insuranceFile: File): Promise<Taxi> {
    let taxi;
    return TaxisStore.findById(taxiId)
      .then((foundTaxi) => {
        taxi = foundTaxi;
        Backendless.Files.remove(taxi.insuranceFile);

        const path = `${environment.backendless.PICTURES_PATH.TAXIS}/${taxi.objectId}`;
        return Backendless.Files.upload(insuranceFile, path, true);
      })
      .then((response: any) => {
        taxi.insuranceFile = response.fileURL;
        return TaxisStore.save(taxi);
      })
      .then(() => {
        const whereClause = `objectId = '${taxiId}'`;

        const queryBuilder = Backendless.DataQueryBuilder.create();
        queryBuilder
            .setWhereClause(whereClause)
            .setRelated(['driver',
                         'taxiOwner',
                         'taxiConcessionaire']);

        return TaxisStore.find(queryBuilder)
      })
      .then((taxiFound: Taxi[]) => {
        return taxiFound[0];
      })
  }

  public editTaxiById(editTaxiRequestBody: EditTaxiRequestBody): Promise<Taxi> {
    return Backendless.CustomServices.invoke('AdminService', 'editTaxiById', editTaxiRequestBody)
  }

  public generateTaxiQrCode(taxiCarObjectId: string): Promise<any> {
    return Backendless.CustomServices.invoke('AdminService', 'generateTaxiCarQrCode', taxiCarObjectId)
  }

  public getAdditionalServices(): Promise<Extra[]> {
    return Backendless.CustomServices.invoke('CommonService', 'getExtras', {})
  }
}
