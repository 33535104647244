<section>
  <div class="p-grid p-mt-2 p-p-2">
    <p class="p-col-12">Servicios que atenderá este taxi: </p>
    <div class="p-col-6" *ngFor="let additionalService of additionalServices">
      <p-checkbox name="services" value="{{additionalService.objectId}}" label="{{additionalService.name}}"
          [disabled]="isSubmitting" [(ngModel)]="selectedServices"></p-checkbox>
    </div>
  </div>

  <div class="p-text-right p-mt-4">
    <p-progressBar *ngIf="isSubmitting || isLoading" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
    </p-progressBar>
    <button pButton icon="pi pi-check" (click)="onSaveServices()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>
