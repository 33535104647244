import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import Utilities from '../helpers/utilities';
import { TaxisService } from '../services/data/taxis.service';
import { RegisterTaxiRequestBody } from '../models/local';
import { Extra, TaxiConcessionaire, TaxiOwner } from './../models/remote';
import { OwnersService } from '../services/data/owners.service';
import { ConcessionairesService } from './../services/data/concessionaires.service';

@Component({
  selector: 'app-create-taxi-dialog',
  templateUrl: './create-taxi-dialog.component.html',
  styleUrls: ['./create-taxi-dialog.component.scss']
})
export class CreateTaxiDialogComponent implements OnInit {

  form: FormGroup;
  isSubmitting: boolean = false;
  today = new Date();

  pictureFile: File;
  insuranceFile: File;

  taxiOwner: TaxiOwner;
  taxiConcessionaire: TaxiConcessionaire;
  ownerSuggestions: TaxiOwner[] = [];
  concessionaireSuggestions: TaxiConcessionaire[] = [];

  additionalServices: Extra[] = [];
  selectedServices: string[] = [];

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService,
              private dialogRef: DynamicDialogRef,
              private taxisService: TaxisService,
              private ownersService: OwnersService,
              private concessionairesService: ConcessionairesService) {
  }

  ngOnInit(): void {
    this.today.setHours(0,0,0,0);

    this.form = this.formBuilder.group({
      plate: new FormControl('', Validators.required),
      concessionNumber: new FormControl('', Validators.required),
      brand: new FormControl('', Validators.required),
      model: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      insuranceCompany: new FormControl('', Validators.required),
      insuranceValidity: new FormControl('', Validators.required),

      policyNumber: new FormControl('', Validators.required)
    });

    this.getAdditionalServices();
  }

  private checkFormValidity(): boolean {

    const keysToCheck = [
      {key: 'plate', message: 'Verifica las placas'},
      {key: 'concessionNumber', message: 'Verifica el número de concesión'},
      {key: 'brand', message: 'Verifica la marca'},
      {key: 'model', message: 'Verifica el modelo'},
      {key: 'year', message: 'Verifica el año'},
      {key: 'insuranceCompany', message: 'Verifica la compañía de seguro'},
      {key: 'insuranceValidity', message: 'Verifica la vigencia del seguro'},
      {key: 'policyNumber', message: 'Verifica el número de poliza'}
    ];

    const message = Utilities.findFormInvalidMessage(this.form, keysToCheck);

    if (message) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'warn',
        detail: message,
        closable: false
      });
      return false;
    }

    return !message;
  }

  private checkSpecificValidations(): boolean {
    if(!this.pictureFile) {
      this.showInvaidError('No es posible dejar al taxi sin foto')
      return false;
    }

    if(!this.insuranceFile) {
      this.showInvaidError('No es posible dejar al taxi sin archivo de seguro')
      return false;
    }

    if(!this.taxiOwner) {
      this.showInvaidError('No es posible dejar al taxi sin un dueño')
      return false;
    }

    if(!this.taxiConcessionaire) {
      this.showInvaidError('No es posible dejar al taxi sin un concesionario')
      return false;
    }

    const formValue = this.form.value
    let date: Date = new Date(formValue.insuranceValidity);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    if(date.getTime() < this.today.getTime() || !date.getTime()) {
      this.showInvaidError('La fecha de vigencia del seguro no es válida')
      return false;
    }

    if(formValue.year.toString().length !== 4) {
      this.showInvaidError('Año no válido')
      return false;
    }

    return true;
  }

  onPictureFileUpload($event: any) {
    this.pictureFile = $event.files[0];
  }

  onRemovePicture($event: any) {
    this.pictureFile = null;
  }

  onInsuranceFileUpload($event: any) {
    this.insuranceFile = $event.files[0];
  }

  onRemoveInsurance($event: any) {
    this.insuranceFile = null;
  }

  onSaveTaxi() {
    if (!this.checkFormValidity()) {
      return;
    }
    if(!this.checkSpecificValidations()) {
      return;
    }

    const formValue = this.form.value;
    let date: Date = new Date(formValue.licenseValidity);
    date.setMinutes( date.getMinutes() + date.getTimezoneOffset() );

    const requestBody: RegisterTaxiRequestBody = {
      brand: formValue.brand,
      insuranceCompany: formValue.insuranceCompany,
      concessionNumber: formValue.concessionNumber,
      insuranceFile: this.insuranceFile,
      insuranceValidity: formValue.insuranceValidity,
      model: formValue.model,
      year: formValue.year,
      plate: formValue.plate,
      picture: this.pictureFile,
      policyNumber: formValue.policyNumber,
      taxiConcessionaireId: this.taxiConcessionaire.objectId,
      taxiOwnerId: this.taxiOwner.objectId,
      additionalServices: this.selectedServices
    };

    this.isSubmitting = true;
    this.taxisService.createTaxi(requestBody).then(taxiResponse => {
      this.isSubmitting = false;
      return this.taxisService.generateTaxiQrCode(taxiResponse.objectId)
    }).
    then(response => {
      console.log(response);
      this.dialogRef.close(true);
    })
    .catch(error => {
      this.isSubmitting = false;
      console.log('[ Create-Taxi ] Could not create a taxi: ', error)
      error = Utilities.getTranslatedBackendlessError(error)
      this.showInvaidError(error.message)
    })
  }

  searchOwner(event: { originalEvent, query }) {
    this.ownersService.searchOwnersForAssignation(event.query).then(owners => {
      this.ownerSuggestions = owners;
    }).catch(error => {
      this.ownerSuggestions = [];
      console.error('[ OwnerAssign ] Could not search owners: ', error);
      this.showInvaidError(error.message);
    });
  }

  selectOwner(owner: TaxiOwner) {
    if (!owner) return;

    this.taxiOwner = owner;
  }

  unselectOwner() {
    this.taxiOwner = null;
  }

  searchConcessionaire(event: { originalEvent, query }) {
    this.concessionairesService.searchConcessionairesForAssignation(event.query).then(concessionaires => {
      this.concessionaireSuggestions = concessionaires;
    }).catch(error => {
      this.concessionaireSuggestions = [];
      console.error('[ ConcessionaireAssign ] Could not search concessionaires: ', error);
      this.showInvaidError(error.message);
    });
  }

  selectConcessionaire(concessionaire: TaxiConcessionaire) {
    if (!concessionaire) return;

    this.taxiConcessionaire = concessionaire;
  }

  unselectConcessionaire() {
    this.taxiConcessionaire = null;
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  private showInvaidError(message: string) {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'error',
      summary: 'Inválido',
      detail: message || 'Algo salió mal. Intenta de nuevo.',
      closable: false
    });
  }

  private getAdditionalServices(): Promise<any> {
    return this.taxisService.getAdditionalServices().then((extrasResponse: Extra[]) => {
      if(!extrasResponse[0]) {
        return
      }
      this.additionalServices = extrasResponse;
      this.selectedServices = extrasResponse.map(extra => extra.objectId)
      console.log(this.additionalServices);
    })
  }

}
