import { Component, OnInit } from '@angular/core';
import { Driver, Passenger, TaxiConcessionaire, TaxiOwner } from '../models/remote';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { PassengersService } from '../services/data/passengers.service';
import { DriversService } from '../services/data/drivers.service';
import { DATA_TO_CHANGE, EditUserRequestBody, USER_TYPE } from '../models/local';
import { OwnersService } from '../services/data/owners.service';
import { ConcessionairesService } from '../services/data/concessionaires.service';

@Component({
  selector: 'app-change-number-dialog',
  templateUrl: './change-number-dialog.component.html',
  styleUrls: ['./change-number-dialog.component.scss']
})
export class ChangeNumberDialogComponent implements OnInit {

  isSubmitting: Boolean = false;

  passengerStore: Passenger;
  driverStore: Driver;
  ownerStore: TaxiOwner;
  concessionaireStore: TaxiConcessionaire

  numberStored: string;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private passengersService: PassengersService,
              private driversService: DriversService,
              private ownersService: OwnersService,
              private concessionairesService: ConcessionairesService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    if(this.dialogConfig.data.dataStore.type === USER_TYPE.PASSENGER) {
      this.passengerStore = this.dialogConfig.data.dataStore;
      this.numberStored = this.passengerStore.mobilePhoneNumber;
    } else if(this.dialogConfig.data.dataStore.type === USER_TYPE.DRIVER){
      this.driverStore = this.dialogConfig.data.dataStore;
      this.numberStored = this.driverStore.mobilePhoneNumber;
    } else if(this.dialogConfig.data.dataStore.address) {
      this.concessionaireStore = this.dialogConfig.data.dataStore;
      this.numberStored = this.concessionaireStore.mobilePhoneNumber;
    } else {
      this.ownerStore = this.dialogConfig.data.dataStore;
      this.numberStored = this.ownerStore.mobilePhoneNumber;
    }
  }

  onSaveNumber() {
    if(this.numberStored.length !== 10) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: 'El número telefónico debe ser de 10 digitos',
        closable: false
      });
      return ;
    }

    if(this.passengerStore) {

      const editRequestBody: EditUserRequestBody = {
        userId: this.passengerStore.objectId,
        newData: this.numberStored,
        dataToChange: DATA_TO_CHANGE.NUMBER
      }

      this.isSubmitting = true;
      this.passengersService.editPassengerById(editRequestBody).then(passengerResponse => {
        this.dialogRef.close(passengerResponse);
        this.isSubmitting = false;
        this.showSuccessMessage();
      })
      .catch(error => {
        this.isSubmitting = false;
        console.error('[ Passenger-Profile ] Could not save number: ', error);

        let message = error.message || 'Algo salió mal. Intenta de nuevo.';
        this.messageService.add({
          key: 'app-main-toast',
          severity: 'error',
          summary: 'Inválido',
          detail: message,
          closable: false
        });
      });
    }

    if(this.driverStore) {
      const editRequestBody: EditUserRequestBody = {
        userId: this.driverStore.objectId,
        newData: this.numberStored,
        dataToChange: DATA_TO_CHANGE.NUMBER
      }

      this.isSubmitting = true;
      this.driversService.editDriverById(editRequestBody).then(driverResponse => {
        this.dialogRef.close(driverResponse);
        this.isSubmitting = false;
        this.showSuccessMessage();
      })
      .catch(error => {
        this.isSubmitting = false;
        console.error('[ Driver-Profile ] Could not save number: ', error);

        let message = error.message || 'Algo salió mal. Intenta de nuevo.';
        this.messageService.add({
          key: 'app-main-toast',
          severity: 'error',
          summary: 'Inválido',
          detail: message,
          closable: false
        });
      });
    }

    if(this.concessionaireStore) {
      const editRequestBody: EditUserRequestBody = {
        userId: this.concessionaireStore.objectId,
        newData: this.numberStored,
        dataToChange: DATA_TO_CHANGE.NUMBER
      }

      this.isSubmitting = true;
      this.concessionairesService.editConcessionaireById(editRequestBody).then(concessionaireResponse => {
        this.dialogRef.close(concessionaireResponse);
        this.isSubmitting = false;
        this.showSuccessMessage();
      })
      .catch(error => {
        this.isSubmitting = false;
        console.error('[ Concessionaire-Deatail ] Could not save number: ', error);

        let message = error.message || 'Algo salió mal. Intenta de nuevo.';
        this.messageService.add({
          key: 'app-main-toast',
          severity: 'error',
          summary: 'Inválido',
          detail: message,
          closable: false
        });
      });
    }

    if(this.ownerStore) {

      const editRequestBody: EditUserRequestBody = {
        userId: this.ownerStore.objectId,
        newData: this.numberStored,
        dataToChange: DATA_TO_CHANGE.NUMBER
      }

      this.isSubmitting = true;
      this.ownersService.editOwnerById(editRequestBody).then(ownerResponse => {
        this.dialogRef.close(ownerResponse);
        this.isSubmitting = false;
        this.showSuccessMessage();
        return ;
      })
      .catch(error => {
        this.isSubmitting = false;
        console.error('[ Owner-Detail ] Could not save number: ', error);

        let message = error.message || 'Algo salió mal. Intenta de nuevo.';
        this.messageService.add({
          key: 'app-main-toast',
          severity: 'error',
          summary: 'Inválido',
          detail: message,
          closable: false
        });
        return ;
      });
    }
  }

  onCancel() {
    if(this.passengerStore) {
      this.dialogRef.close(this.passengerStore);
    }
    if(this.passengerStore) {
      this.dialogRef.close(this.driverStore);
    }
    if(this.concessionaireStore) {
      this.dialogRef.close(this.concessionaireStore);
    }
    this.dialogRef.close(this.driverStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
