import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DATA_TO_CHANGE, EditUserRequestBody } from '../models/local';
import { Driver } from '../models/remote';
import { DriversService } from '../services/data/drivers.service';

@Component({
  selector: 'app-change-curp-dialog',
  templateUrl: './change-curp-dialog.component.html',
  styleUrls: ['./change-curp-dialog.component.scss']
})
export class ChangeCurpDialogComponent implements OnInit {

  isSubmitting: Boolean = false;

  driverStore: Driver;

  curpStored: string;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private driversService: DriversService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
      this.driverStore = this.dialogConfig.data.dataStore;
      this.curpStored = this.driverStore.driverProfile.curp;
  }

  onSaveCurp() {
    if(this.curpStored.length !== 18) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: 'Curp incorrecto, debe ser de 18 caracteres',
        closable: false
      });
      return ;
    }

    const editRequestBody: EditUserRequestBody = {
      userId: this.driverStore.objectId,
      newData: this.curpStored,
      dataToChange: DATA_TO_CHANGE.CURP
    }

    this.isSubmitting = true;
    this.driversService.editDriverById(editRequestBody).then(driverResponse => {
      this.dialogRef.close(driverResponse);
      this.isSubmitting = false;
      this.showSuccessMessage();
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Driver-Profile ] Could not save curp: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });

  }

  onCancel() {
    this.dialogRef.close(this.driverStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }


}
