import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Taxi } from '../models/remote';
import { TaxisService } from '../services/data/taxis.service';
import { MessageService } from 'primeng/api';
import { DATA_TO_CHANGE, EditTaxiRequestBody } from '../models/local';

@Component({
  selector: 'app-change-taxi-data-dialog',
  templateUrl: './change-taxi-data-dialog.component.html',
  styleUrls: ['./change-taxi-data-dialog.component.scss']
})
export class ChangeTaxiDataDialogComponent implements OnInit {

  isSubmitting: Boolean = false;

  taxiStore: Taxi;

  brandStored: string;
  modelStored: string;
  yearStored: number;
  plateStored: string;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private taxisService: TaxisService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.taxiStore = this.dialogConfig.data.dataStore;

    this.brandStored = this.taxiStore.brand;
    this.modelStored = this.taxiStore.model;
    this.yearStored = this.taxiStore.year;
    this.plateStored = this.taxiStore.plate;
  }

  onSaveData() {
    const message: string = this.validateTaxiData();
    if(message) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    }


    const editRequestBody: EditTaxiRequestBody = {
      objectId: this.taxiStore.objectId,
      brand: this.brandStored,
      model: this.modelStored,
      year: this.yearStored,
      plate: this.plateStored,
      dataToChange: DATA_TO_CHANGE.TAXI_DATA
    }

    this.isSubmitting = true;
    this.taxisService.editTaxiById(editRequestBody).then(taxiResponse => {
      this.dialogRef.close(taxiResponse);
      this.isSubmitting = false;
      this.showSuccessMessage();
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Taxi-detail ] Could not save data: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });

  }

  onCancel() {
    this.dialogRef.close(this.taxiStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

  private validateTaxiData(): string {
    if(!this.brandStored.length) {
      return 'No es posible dejar al vehiculo sin una marca'
    }
    if(!this.modelStored.length) {
      return 'No es posible dejar al vehiculo sin un modelo'
    }
    if(this.yearStored.toString().length !== 4) {
      return 'Año no valido'
    }
  }

}
