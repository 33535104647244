import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DATA_TO_CHANGE, EditUserRequestBody } from '../models/local';
import { Driver } from '../models/remote';
import { DriversService } from '../services/data/drivers.service';

@Component({
  selector: 'app-change-date-dialog',
  templateUrl: './change-date-dialog.component.html',
  styleUrls: ['./change-date-dialog.component.scss']
})
export class ChangeDateDialogComponent implements OnInit {

  isSubmitting: Boolean = false;

  driverStore: Driver;

  today = new Date();
  licenseValidity: Date;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private driversService: DriversService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.driverStore = this.dialogConfig.data.dataStore;
    this.today.setHours(0,0,0,0);
  }

  onSaveDate() {
    let date: Date = new Date(this.licenseValidity);
    date.setMinutes( date.getMinutes() + date.getTimezoneOffset() );

    if(date.getTime() < this.today.getTime() || !date.getTime()) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: 'La fecha de licencia de vigencia no es válida',
        closable: false
      });
      return ;
    }

    const editRequestBody: EditUserRequestBody = {
      userId: this.driverStore.objectId,
      newDate: date,
      dataToChange: DATA_TO_CHANGE.LICENSE_VALIDITY
    }

    this.isSubmitting = true;
    this.driversService.editDriverById(editRequestBody).then(driverResponse => {
      this.dialogRef.close(driverResponse);
      this.isSubmitting = false;
      this.showSuccessMessage();
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Driver-Profile ] Could not save license validity: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });

  }

  onCancel() {
    this.dialogRef.close(this.driverStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
