<section class="p-m-4">
  <form [formGroup]="form">

    <div class="p-grid">

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup p-mt-4">
          <span class="ui-inputgroup-addon"><i class="pi pi-clone"></i></span>
          <input type="text" class="iks-fullWidth" formControlName="plate"
                placeholder="Placas"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.plate.touched && !form.controls.plate.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica las placas"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        <p>Foto de taxi:</p>
        <p-fileUpload accept="image/*"
                            chooseLabel="Elegir Imagen"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onSelect)="onPictureFileUpload($event)"
                            (onRemove)="onRemovePicture($event)"></p-fileUpload>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-ticket"></i></span>
          <input type="text" class="iks-fullWidth"
                formControlName="concessionNumber"
                placeholder="Número de concesión"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.concessionNumber.touched && !form.controls.concessionNumber.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica el número de concesión"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-chevron-circle-down"></i></span>
          <input type="text" class="iks-fullWidth" formControlName="brand"
                placeholder="Marca"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.brand.touched && !form.controls.brand.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica la marca"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-chevron-circle-down"></i></span>
          <input type="text" class="iks-fullWidth" formControlName="model"
                placeholder="Línea"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.model.touched && !form.controls.model.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica la línea"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-chevron-circle-down"></i></span>
          <input type="number" class="iks-fullWidth" formControlName="year"
                placeholder="Modelo"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.year.touched && !form.controls.year.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica el modelo"></p-message>
      </div>

      <div *ngIf="!taxiOwner" class="p-col-12 p-md-6">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-user"></i></span>
          <p-autoComplete
            class="iks-fullWidth"
            placeholder="Dueño"
            [minLength]="3"
            [suggestions]="ownerSuggestions"
            (completeMethod)="searchOwner($event)"
            (onSelect)="selectOwner($event)">
            <ng-template let-ownerItem pTemplate="item">
              <div class="p-grid">
                <div class="p-col-10">
                  <div>{{ownerItem.name}}</div>
                  <div>Teléfono: {{ownerItem.mobilePhoneNumber}}</div>
                </div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>

      <div *ngIf="taxiOwner" class="p-md-6 p-grid">
        <div class="p-col-9">
          <div class="iks-selectionBox__inner">
            <div>{{taxiOwner.name}} </div>
            <div>Teléfono: {{taxiOwner.mobilePhoneNumber}}</div>
            <button pButton
                    label="Cambiar" class="ui-button-secondary p-mt-2"
                    (click)="unselectOwner()"></button>
          </div>
        </div>
      </div>

      <div *ngIf="!taxiConcessionaire" class="p-col-12 p-md-6">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-user"></i></span>
          <p-autoComplete
            class="iks-fullWidth"
            placeholder="Concesionario"
            [minLength]="3"
            [suggestions]="concessionaireSuggestions"
            (completeMethod)="searchConcessionaire($event)"
            (onSelect)="selectConcessionaire($event)">
            <ng-template let-concessionaireItem pTemplate="item">
              <div class="p-grid">
                <div class="p-col-10">
                  <div>{{concessionaireItem.name}}</div>
                  <div>Teléfono: {{concessionaireItem.mobilePhoneNumber}}</div>
                </div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>

      <div *ngIf="taxiConcessionaire" class="p-md-6 p-grid">
        <div class="p-col-9">
          <div class="iks-selectionBox__inner">
            <div>{{taxiConcessionaire.name}} </div>
            <div>Teléfono: {{taxiConcessionaire.mobilePhoneNumber}}</div>
            <button pButton
                    label="Cambiar" class="ui-button-secondary p-mt-2"
                    (click)="unselectConcessionaire()"></button>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-md-6">
        <p>Seguro (Archivo):</p>
        <p-fileUpload accept="image/*,.pdf"
                            chooseLabel="Elegir Archivo"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onSelect)="onInsuranceFileUpload($event)"
                            (onRemove)="onRemoveInsurance($event)"></p-fileUpload>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup p-mt-4">
          <span class="ui-inputgroup-addon"><i class="pi pi-lock"></i></span>
          <input type="text" class="iks-fullWidth" formControlName="insuranceCompany"
                placeholder="Compañía de seguro"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.insuranceCompany.touched && !form.controls.insuranceCompany.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica la compañía de seguro"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        <p>Vigencia de seguro:</p>
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-calendar"></i></span>
          <input type="date" class="iks-fullWidth" formControlName="insuranceValidity"
                min="{{today | date:'yyyy-MM-dd'}}"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.insuranceValidity.touched && !form.controls.insuranceValidity.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica la vigencia del seguro"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup p-mt-4">
          <span class="ui-inputgroup-addon"><i class="pi pi-briefcase"></i></span>
          <input type="text" class="iks-fullWidth" formControlName="policyNumber"
                placeholder="Número de poliza"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.policyNumber.touched && !form.controls.policyNumber.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica el número de poliza"></p-message>
      </div>

    </div>

  </form>

  <div class="p-grid iks-elevate-2 p-mt-2 p-p-2">
    <p class="p-col-12">Servicios que atenderá este taxi: </p>
    <div class="p-col-4" *ngFor="let additionalService of additionalServices">
      <p-checkbox name="services" value="{{additionalService.objectId}}" label="{{additionalService.name}}"
          [(ngModel)]="selectedServices"></p-checkbox>
    </div>
  </div>

  <div class="p-text-right p-mt-4">
    <p-progressBar *ngIf="isSubmitting" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
    </p-progressBar>
    <button pButton icon="pi pi-check" (click)="onSaveTaxi()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>
