import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Taxi } from '../models/remote';
import { TaxisService } from '../services/data/taxis.service';
import { MessageService } from 'primeng/api';
import { TAXI_DATA_TO_CHANGE, EditTaxiRequestBody } from '../models/local';

@Component({
  selector: 'app-change-policy-number-dialog',
  templateUrl: './change-policy-number-dialog.component.html',
  styleUrls: ['./change-policy-number-dialog.component.scss']
})
export class ChangePolicyNumberDialogComponent implements OnInit {

  isSubmitting: Boolean = false;

  taxiStore: Taxi;

  policyNumberStored: string;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private taxisService: TaxisService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.taxiStore = this.dialogConfig.data.dataStore;

    this.policyNumberStored = this.taxiStore.policyNumber;
  }

  onSavePolicyNumber() {

    const editRequestBody: EditTaxiRequestBody = {
      objectId: this.taxiStore.objectId,
      policyNumber: this.policyNumberStored,
      dataToChange: TAXI_DATA_TO_CHANGE.POLICY_NUMBER
    }

    this.isSubmitting = true;
    this.taxisService.editTaxiById(editRequestBody).then(taxiResponse => {
      this.dialogRef.close(taxiResponse);
      this.isSubmitting = false;
      this.showSuccessMessage();
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Taxi-detail ] Could not save data: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });

  }

  onCancel() {
    this.dialogRef.close(this.taxiStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
