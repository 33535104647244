import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Taxi } from '../models/remote';
import { TaxisService } from '../services/data/taxis.service';
import { MessageService } from 'primeng/api';
import { TAXI_DATA_TO_CHANGE, EditTaxiRequestBody } from '../models/local';

@Component({
  selector: 'app-change-insurance-validity-dialog',
  templateUrl: './change-insurance-validity-dialog.component.html',
  styleUrls: ['./change-insurance-validity-dialog.component.scss']
})
export class ChangeInsuranceValidityDialogComponent implements OnInit {

  isSubmitting: Boolean = false;

  taxiStore: Taxi;
  today = new Date();
  insuranceValidityStored: Date;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private taxisService: TaxisService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.taxiStore = this.dialogConfig.data.dataStore;
    this.today.setHours(0,0,0,0);
  }

  onSaveInsuranceValidity() {

    let date: Date = new Date(this.insuranceValidityStored);
    date.setMinutes( date.getMinutes() + date.getTimezoneOffset() );

    if(date.getTime() < this.today.getTime() || !date.getTime()) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: 'La fecha de vigencia del Seguro no es válida',
        closable: false
      });
      return ;
    }

    const editRequestBody: EditTaxiRequestBody = {
      objectId: this.taxiStore.objectId,
      insuranceValidity: date,
      dataToChange: TAXI_DATA_TO_CHANGE.INSURANCE_VALIDITY
    }

    this.isSubmitting = true;
    this.taxisService.editTaxiById(editRequestBody).then(taxiResponse => {
      this.dialogRef.close(taxiResponse);
      this.isSubmitting = false;
      this.showSuccessMessage();
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Taxi-detail ] Could not save data: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });

  }

  onCancel() {
    this.dialogRef.close(this.taxiStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
