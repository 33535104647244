import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditTaxiRequestBody, TAXI_DATA_TO_CHANGE } from '../models/local';
import { Extra, Taxi } from '../models/remote';
import { TaxisService } from '../services/data/taxis.service';

@Component({
  selector: 'app-change-taxi-services-dialog',
  templateUrl: './change-taxi-services-dialog.component.html',
  styleUrls: ['./change-taxi-services-dialog.component.scss']
})
export class ChangeTaxiServicesDialogComponent implements OnInit {

  isSubmitting: boolean = false;
  isLoading: boolean = true;

  taxiStore: Taxi;
  additionalServices: Extra[] = [];
  selectedServices: string[] = [];

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private taxisService: TaxisService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.taxiStore = this.dialogConfig.data.dataStore;
    this.getAdditionalServices();
  }

  private getAdditionalServices(): Promise<any> {
    return this.taxisService.getAdditionalServices().then((extrasResponse: Extra[]) => {
      if(!extrasResponse[0]) {
        return
      }
      this.additionalServices = extrasResponse;
      this.selectedServices = this.taxiStore.acceptedExtras.map(extra => extra.objectId)
      this.isLoading = false;
    })
  }

  onSaveServices() {
    this.isSubmitting = true;

    const editRequestBody: EditTaxiRequestBody = {
      objectId: this.taxiStore.objectId,
      dataToChange: TAXI_DATA_TO_CHANGE.ACCEPTED_SERVICES,
      acceptedServices: this.selectedServices
    }

    this.taxisService.editTaxiById(editRequestBody).then(taxiResponse => {
      this.dialogRef.close(taxiResponse);
      this.isSubmitting = false;
      this.showSuccessMessage();
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Taxi-detail ] Could not save data: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
