import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Taxi } from '../models/remote';
import { TaxisService } from '../services/data/taxis.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Driver } from './../models/remote';
import { DriversService } from '../services/data/drivers.service';
import { DATA_TO_CHANGE, EditTaxiRequestBody } from '../models/local';

@Component({
  selector: 'app-change-taxi-driver-dialog',
  templateUrl: './change-taxi-driver-dialog.component.html',
  styleUrls: ['./change-taxi-driver-dialog.component.scss']
})
export class ChangeTaxiDriverDialogComponent implements OnInit {

  isSubmitting: Boolean = false;
  driverSuggestions: Driver[] = [];
  driver: Driver;

  taxiStore: Taxi;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private taxisService: TaxisService,
              private driversService: DriversService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.taxiStore = this.dialogConfig.data.dataStore;
  }

  onSaveDriver() {
    if(!this.driver) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: 'No se ha seleccionado un conductor',
        closable: false
      });
      return ;
    }

    const editRequestBody: EditTaxiRequestBody = {
      objectId: this.taxiStore.objectId,
      driverId: this.driver.objectId,
      dataToChange: DATA_TO_CHANGE.ASSIGN_DRIVER
    }

    this.isSubmitting = true;
    this.taxisService.editTaxiById(editRequestBody).then(taxiResponse => {
      this.dialogRef.close(taxiResponse);
      this.isSubmitting = false;
      this.showSuccessMessage();
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Taxi-detail ] Could not save data: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });

  }

  searchDriver(event: { originalEvent, query }) {
    this.driversService.searchDriversForAssignation(event.query).then(drivers => {
      this.driverSuggestions = drivers;
    }).catch(error => {
      this.driverSuggestions = [];
      console.error('[ DriverAssign ] Could not search drivers: ', error);
      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
    });
  }

  selectDriver(driver: Driver) {
    if (!driver) return;

    this.driver = driver;
  }

  unselectDriver() {
    this.driver = null;
  }

  onCancel() {
    this.dialogRef.close(this.taxiStore);
  }

  private removeDriver() {
    const editRequestBody: EditTaxiRequestBody = {
      objectId: this.taxiStore.objectId,
      driverId: this.taxiStore.driver.objectId,
      dataToChange: DATA_TO_CHANGE.REMOVE_DRIVER
    }

    this.isSubmitting = true;
    this.taxisService.editTaxiById(editRequestBody).then(taxiResponse => {
      this.dialogRef.close(taxiResponse);
      this.isSubmitting = false;
      this.showSuccessMessage();
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Taxi-detail ] Could not remove driver: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });
  }

  onConfirmRemove() {
    this.confirmationService.confirm({
      message: '¿Deseas dejar sin conductor asignado a este vehiculo?',
      acceptLabel: 'Aceptar',
      rejectLabel: 'Cancelar',
      rejectButtonStyleClass: 'ui-button-secondary',
      key: 'driver',
      accept: () => {
          this.removeDriver();
      }
  });
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
