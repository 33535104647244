<section>
  <div class="p-float-label p-m-4">
    Seguro (Archivo):
    <p-fileUpload accept="image/*,.pdf"
                            chooseLabel="Elegir Archivo"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onSelect)="onFileUpload($event)"
                            (onRemove)="onRemoveFile($event)"></p-fileUpload>
  </div>

  <p-progressBar *ngIf="isSubmitting" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
  </p-progressBar>

  <div class="p-text-right">
    <button pButton icon="pi pi-check" (click)="onSaveFile()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>
