<section class="p-m-4">
  <form [formGroup]="form">

    <div class="p-grid">

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-user"></i></span>
          <input type="text" class="iks-fullWidth" formControlName="name"
                placeholder="Nombre"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.name.touched && !form.controls.name.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica el nombre"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-mobile"></i></span>
          <input type="tel" class="iks-fullWidth"
                formControlName="mobilePhoneNumber"
                placeholder="Teléfono Celular"
                [readOnly]="isSubmitting" inputmode="tel" pInputText>
        </div>
        <p-message *ngIf="form.controls.mobilePhoneNumber.touched && !form.controls.mobilePhoneNumber.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica el teléfono celular"></p-message>
      </div>

    </div>
  </form>

  <div class="p-text-right p-mt-4">
    <p-progressBar *ngIf="isSubmitting" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
    </p-progressBar>
    <button pButton icon="pi pi-check" (click)="onSaveOwner()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>

