import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DATA_TO_CHANGE, EditTaxiRequestBody } from '../models/local';
import { Taxi, TaxiOwner } from '../models/remote';
import { OwnersService } from '../services/data/owners.service';
import { TaxisService } from '../services/data/taxis.service';

@Component({
  selector: 'app-change-taxi-owner-dialog',
  templateUrl: './change-taxi-owner-dialog.component.html',
  styleUrls: ['./change-taxi-owner-dialog.component.scss']
})
export class ChangeTaxiOwnerDialogComponent implements OnInit {

  isSubmitting: Boolean = false;
  ownerSuggestions: TaxiOwner[] = [];
  owner: TaxiOwner;

  taxiStore: Taxi;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private taxisService: TaxisService,
              private ownersService: OwnersService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.taxiStore = this.dialogConfig.data.dataStore;
  }

  onSaveOwner() {
    if(!this.owner) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: 'No se ha seleccionado un dueño',
        closable: false
      });
      return ;
    }

    const editRequestBody: EditTaxiRequestBody = {
      objectId: this.taxiStore.objectId,
      ownerId: this.owner.objectId,
      dataToChange: DATA_TO_CHANGE.CHANGE_OWNER
    }

    this.isSubmitting = true;
    this.taxisService.editTaxiById(editRequestBody).then(taxiResponse => {
      this.dialogRef.close(taxiResponse);
      this.isSubmitting = false;
      this.showSuccessMessage();
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Taxi-detail ] Could not save data: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });

  }

  searchOwner(event: { originalEvent, query }) {
    this.ownersService.searchOwnersForAssignation(event.query).then(owners => {
      this.ownerSuggestions = owners;
    }).catch(error => {
      this.ownerSuggestions = [];
      console.error('[ OwnerAssign ] Could not search owners: ', error);
      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
    });
  }

  selectOwner(owner: TaxiOwner) {
    if (!owner) return;

    this.owner = owner;
  }

  unselectOwner() {
    this.owner = null;
  }

  onCancel() {
    this.dialogRef.close(this.taxiStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
