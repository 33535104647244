<section>
  <div class="p-float-label p-m-4">
    Concesionario:
    <p-autoComplete
        *ngIf="!concessionaire"
        class="iks-fullWidth"
        [minLength]="3"
        [suggestions]="concessionaireSuggestions"
        (completeMethod)="searchConcessionaire($event)"
        (onSelect)="selectConcessionaire($event)">
        <ng-template let-concessionaireItem pTemplate="item">
          <div class="p-grid">
            <div class="p-col-10">
              <div>{{concessionaireItem.name}}</div>
              <div>Teléfono: {{concessionaireItem.mobilePhoneNumber}}</div>
            </div>
          </div>
        </ng-template>
    </p-autoComplete>

    <div *ngIf="concessionaire" class="p-grid">
      <div class="p-col-9">
        <div class="iks-selectionBox__inner">
          <div>{{concessionaire.name}}</div>
          <div>Teléfono: {{concessionaire.mobilePhoneNumber}}</div>
          <button pButton
                  label="Cambiar" class="ui-button-secondary p-mt-2"
                  (click)="unselectConcessionaire()"></button>
        </div>
      </div>
    </div>
  </div>

  <p-progressBar *ngIf="isSubmitting" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
  </p-progressBar>

  <div class="p-text-right">
    <button pButton icon="pi pi-check" (click)="onSaveConcessionaire()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>

