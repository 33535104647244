<section>
  <div class="p-float-label p-m-4">
    Conductor:
    <p-autoComplete
        *ngIf="!driver"
        class="iks-fullWidth"
        [minLength]="3"
        [suggestions]="driverSuggestions"
        (completeMethod)="searchDriver($event)"
        (onSelect)="selectDriver($event)">
        <ng-template let-driverItem pTemplate="item">
          <div class="p-grid">
            <div class="p-col-2">
              <div>
                <img class="iks-fullWidth" [src]="driverItem.driverProfile.picture">
              </div>
            </div>
            <div class="p-col-10">
              <div>{{driverItem.name}} {{driverItem.driverProfile.rating}}</div>
              <div>{{driverItem.mobilePhoneNumber}}</div>
            </div>
          </div>
        </ng-template>
      </p-autoComplete>

      <div *ngIf="driver" class="p-grid">
        <div class="p-col-3">
          <div class="iks-selectionBox__inner">
            <div class="iks-squareImg">
              <img [src]="driver.driverProfile.picture">
            </div>
          </div>
        </div>
        <div class="p-col-9">
          <div class="iks-selectionBox__inner">
            <div>{{driver.name}} {{driver.driverProfile.rating}}</div>
            <div>{{driver.mobilePhoneNumber}}</div>
            <button pButton
                    label="Cambiar" class="ui-button-secondary p-mt-2"
                    (click)="unselectDriver()"></button>
          </div>
        </div>
      </div>

      <div *ngIf="!driver && taxiStore.driver" class="p-grid p-mt-1">
        <div class="p-col-3">
          <div class="iks-selectionBox__inner">
            <div class="iks-squareImg">
              <img [src]="taxiStore.driver.driverProfile.picture">
            </div>
          </div>
        </div>
        <div class="p-col-9">
          <div class="iks-selectionBox__inner">
            <div>{{taxiStore.driver.name}} {{taxiStore.driver.driverProfile.rating}}</div>
            <div>{{taxiStore.driver.mobilePhoneNumber}}</div>

            <p-confirmDialog header="Desasignar conductor" [key]="'driver'" icon="pi pi-exclamation-triangle"></p-confirmDialog>
            <button pButton
                    label="Desasignar conductor" [disabled]="isSubmitting" class="ui-button-danger p-mt-2"
                    (click)="onConfirmRemove()"></button>
          </div>
        </div>
      </div>
  </div>

  <p-progressBar *ngIf="isSubmitting" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
  </p-progressBar>

  <div class="p-text-right">
    <button pButton icon="pi pi-check" (click)="onSaveDriver()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>
