import { Injectable } from '@angular/core';
import { DATA_TO_CHANGE, EditUserRequestBody, RegisterDriverRequestBody, TableDefaults, TableRequestBody, USER_TYPE } from 'src/app/models/local';
import { Driver } from 'src/app/models/remote';
import { environment } from 'src/environments/environment';

const DriverProfilesStore = Backendless.Data.of('DriverProfiles');
const DriversStore = Backendless.Data.of('Users');

@Injectable({
  providedIn: 'root'
})
export class DriversService {

  constructor() {
  }

  public getDriversForManage(tableRequestBody: TableRequestBody = {
    pageOffset: 0,
    pageSize: TableDefaults.PageSize
  }): Promise<{ drivers: Driver[], totalRows: number }> {
    return Backendless.CustomServices.invoke('AdminService', 'getDriversForManage', tableRequestBody);
  }

  public createDriver(requestBody: RegisterDriverRequestBody): Promise <Driver> {
    const DriverStore = Backendless.Data.of('Users');
    const DriverProfileStore = Backendless.Data.of('DriverProfiles')

    const driver = {
        type: USER_TYPE.DRIVER,
        email: requestBody.email,
        name: requestBody.name,
        mobilePhoneNumber: requestBody.mobilePhoneNumber,
        password: requestBody.password,
        isUserEnabled: true
    }

    const driverProfile = {
        curp: requestBody.curp,
        licenseValidity: requestBody.licenseValidity,
        address: requestBody.address
    }

    let driverStored;
    let profileStored;
    return DriverStore.save(driver).then(persistedDriver => {
      driverStored = persistedDriver;

      return DriverProfileStore.save(driverProfile)
    })
    .then(persistedProfile => {
      profileStored = persistedProfile;

      return DriverStore.setRelation(driverStored, 'driverProfile', [profileStored])
    })
    .then(() => {
      return Backendless.CustomServices.invoke('AdminService', 'enableUserAccount', driverStored.objectId)
    })
    .then(() => {
      const path = `${environment.backendless.PICTURES_PATH.DRIVERS}/${driverStored.objectId}`;

      let picturePromises = [
        Backendless.Files.upload(requestBody.picture, path, true),
        Backendless.Files.upload(requestBody.curpFile, path, true),
        Backendless.Files.upload(requestBody.licenseFile, path, true)
      ];

        return Promise.all(picturePromises);
    })
    .then((responses: any) => {
      profileStored.picture = responses[0].fileURL;
      profileStored.curpFile = responses[1].fileURL;
      profileStored.licenseFile = responses[2].fileURL;
      return DriverProfileStore.save(profileStored);
    })
  }

  public getDriverById(driverId: string): Promise<Driver> {
    return Backendless.CustomServices.invoke('AdminService', 'getDriverById', driverId)
  }

  public editDriverById(editDriverRequestBody: EditUserRequestBody): Promise<Driver> {
    return Backendless.CustomServices.invoke('AdminService', 'editDriverById', editDriverRequestBody)
  }

  public editDriverPicture(driverObject: Driver, pictureFile: File): Promise<Driver> {
    let driver;
    return DriverProfilesStore.findById(driverObject.driverProfile.objectId)
      .then((foundDriver) => {
        driver = foundDriver;
        Backendless.Files.remove(driver.picture);

        const path = `${environment.backendless.PICTURES_PATH.DRIVERS}/${driver.objectId}`;
        return Backendless.Files.upload(pictureFile, path, true);
      })
      .then((response: any) => {
        driver.picture = response.fileURL;
        return DriverProfilesStore.save(driver);
      })
      .then(() => {
        const whereClause = `objectId = '${driverObject.objectId}'`;

        const queryBuilder = Backendless.DataQueryBuilder.create();
        queryBuilder
            .setWhereClause(whereClause)
            .setRelated(['driverProfile']);

        return DriversStore.find(queryBuilder)
      })
      .then((driverFound: Driver[]) => {
        return driverFound[0];
      })
  }

  public editDriverFiles(driverObject: Driver, file: File, fileToChange: string): Promise<Driver> {
    let driver;
    let driverProfile;
    const whereClause = `objectId = '${driverObject.objectId}'`;

    const queryBuilder = Backendless.DataQueryBuilder.create();
    queryBuilder
        .setWhereClause(whereClause)
        .setRelated(['driverProfile']);

    return DriversStore.find(queryBuilder)
      .then((foundDriver) => {
        driver = foundDriver && foundDriver[0];
        driverProfile = driver.driverProfile;

        if(fileToChange === DATA_TO_CHANGE.CURP_FILE)
          Backendless.Files.remove(driverProfile.curpFile);
        else
          Backendless.Files.remove(driverProfile.licenseFile);

        const path = `${environment.backendless.PICTURES_PATH.DRIVERS}/${driver.objectId}`;
        return Backendless.Files.upload(file, path, true);
      })
      .then((response: any) => {
        if(fileToChange === DATA_TO_CHANGE.CURP_FILE)
          driverProfile.curpFile = response.fileURL;
        else
          driverProfile.licenseFile = response.fileURL;

        return DriverProfilesStore.save(driverProfile);
      })
      .then(() => {
        return DriversStore.find(queryBuilder)
      })
      .then((driverFound: Driver[]) => {
        return driverFound[0];
      })
  }

  public searchDriversForAssignation(keyword: string): Promise<Driver[]> {
    return Backendless.CustomServices.invoke('AdminService', 'searchDriversForAssignation', {keyword});
  }
}
