<section>
  <div class="p-float-label p-m-4">
    <img src="{{driverStore ? driverStore.driverProfile.picture : taxiStore.picture}}" class="iks-img p-mb-2">
    <p-fileUpload accept="image/*"
                            chooseLabel="Elegir Imagen"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onSelect)="onPictureUpload($event)"
                            (onRemove)="onRemovePicture($event)"></p-fileUpload>
  </div>

  <p-progressBar *ngIf="isSubmitting" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
  </p-progressBar>

  <div class="p-text-right">
    <button pButton icon="pi pi-check" (click)="onSavePicture()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>
