export interface LoginRequestBody {
  email: string;
  password: string;
}

export interface ResetPasswordRequestBody {
  email: string;
}

export enum Epoch {Year = 'año', Month = 'mes', Day = 'dia', Hour = 'hora', Minute = 'minuto', Second = 'segundo'}

export interface TableRequestBody {
  pageOffset: number;
  pageSize: number;
  sortField?: string;
  sortDir?: number;
  filter?: string;
  userObjectId?: string;
}

export interface EditUserRequestBody {
  userId: string;
  newData?: string;
  newDate?: Date;
  dataToChange: string
  statusToChange?: Boolean;
}

export interface EditTaxiRequestBody {
  objectId: string;
  brand?: string;
  model?: string;
  year?: number;
  plate?: string;
  concessionaireId?: string;
  concessionNumber?: string;
  insuranceCompany?: string;
  insuranceValidity?: Date;
  policyNumber?: string;
  dataToChange: string;
  driverId?: string;
  statusToChange?: boolean;
  ownerId?: string;
  acceptedServices?: string[];
  imei?: string;
}

export interface RegisterDriverRequestBody {
  email: string;
  name: string;
  picture: File;
  address: string;
  curp: string;
  licenseValidity: Date;
  curpFile: File;
  licenseFile: File;
  mobilePhoneNumber: string;
  password: string;
}

export interface RegisterTaxiRequestBody {
  brand: string;
  concessionNumber: string;
  insuranceCompany: string;
  insuranceFile: File;
  insuranceValidity: Date;
  model: string;
  year: number;
  plate: string;
  picture: File;
  policyNumber: string;
  taxiConcessionaireId: string;
  taxiOwnerId: string;
  additionalServices: string[];
}

export const TableDefaults = {
  PageSize: 10
};

export const BigNotificationMessageDuration = 5000;

export const DATA_TO_CHANGE = {
  NUMBER: 'mobilePhoneNumber',
  NAME: 'name',
  PICTURE: 'picture',
  ACCESS: 'access',
  TAXI_DATA: 'taxiData',
  ASSIGN_DRIVER: 'assignDriver',
  REMOVE_DRIVER: 'removeDriver',
  CHANGE_OWNER: 'changeOwner',
  CURP: 'curp',
  LICENSE_VALIDITY: 'licenseValidity',
  CURP_FILE: 'curpFile',
  LICENSE_FILE: 'licenseFile',
  ADDRESS: 'address'
}

export const TAXI_DATA_TO_CHANGE = {
  CONCESSION_NUMBER: 'concessionNumber',
  INSURANCE_COMPANY: 'insuranceCompany',
  INSURANCE_VALIDITY: 'insuranceValidity',
  POLICY_NUMBER: 'policyNumber',
  ACCEPTED_SERVICES: 'acceptedServices',
  CHANGE_CONCESSIONAIRE: 'changeConcessionaire',
  IMEI: 'imei'
}

export const USER_TYPE = {
  DRIVER: 'DRIVER',
  PASSENGER: 'PASSENGER'
}

