import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Driver } from '../models/remote';
import { DriversService } from '../services/data/drivers.service';
import { DATA_TO_CHANGE } from '../models/local';

@Component({
  selector: 'app-change-file-dialog',
  templateUrl: './change-file-dialog.component.html',
  styleUrls: ['./change-file-dialog.component.scss']
})
export class ChangeFileDialogComponent implements OnInit {

  file: File;

  isLoading: Boolean = false;
  isSubmitting: Boolean = false;

  driverStore: Driver;
  fileToChange: string;
  changeCurp: string = DATA_TO_CHANGE.CURP_FILE;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private messageService: MessageService,
              private driversService: DriversService) {
  }

  ngOnInit(): void {
    this.driverStore = this.dialogConfig.data.dataStore;
    this.fileToChange = this.dialogConfig.data.fileToChange;
  }

  onFileUpload($event: any) {
    this.file = $event.files[0];
  }

  onRemoveFile($event: any) {
    this.file = null;
  }

  onSaveFile() {
    if(!this.file) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: 'No se ha seleccionado un archivo',
        closable: false
      });
      return ;
    }

    this.isSubmitting = true;
    this.driversService.editDriverFiles(this.driverStore, this.file, this.fileToChange).then(driverResponse => {
      this.driverStore = driverResponse;
      this.isSubmitting = false;
      this.showSuccessMessage();
      this.dialogRef.close(driverResponse);
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Driver-Profile ] Could not save file: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });

  }

  onCancel() {
    this.dialogRef.close(this.driverStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
