<section>
  <div class="p-float-label p-m-4">
    Fecha: <input type="date" pInputText min="{{today | date:'yyyy-MM-dd'}}" [(ngModel)]="licenseValidity">
  </div>

  <p-progressBar *ngIf="isSubmitting" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
  </p-progressBar>

  <div class="p-text-right">
    <button pButton icon="pi pi-check" (click)="onSaveDate()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>
