import { DATA_TO_CHANGE, EditUserRequestBody, USER_TYPE } from './../models/local';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Driver, Passenger, TaxiConcessionaire, TaxiOwner } from '../models/remote';
import { MessageService } from 'primeng/api';
import { PassengersService } from '../services/data/passengers.service';
import { DriversService } from '../services/data/drivers.service';
import { OwnersService } from '../services/data/owners.service';
import { ConcessionairesService } from './../services/data/concessionaires.service';

@Component({
  selector: 'app-change-name-dialog',
  templateUrl: './change-name-dialog.component.html',
  styleUrls: ['./change-name-dialog.component.scss']
})
export class ChangeNameDialogComponent implements OnInit {

  isSubmitting: Boolean = false;

  passengerStore: Passenger;
  driverStore: Driver;
  ownerStore: TaxiOwner;
  concessionaireStore: TaxiConcessionaire;

  nameStored: string;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private passengersService: PassengersService,
              private driversService: DriversService,
              private ownersService: OwnersService,
              private concessionairesService: ConcessionairesService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    if(this.dialogConfig.data.dataStore.type === USER_TYPE.PASSENGER) {
      this.passengerStore = this.dialogConfig.data.dataStore;
      this.nameStored = this.passengerStore.name;
    } else if(this.dialogConfig.data.dataStore.type === USER_TYPE.DRIVER) {
      this.driverStore = this.dialogConfig.data.dataStore;
      this.nameStored = this.driverStore.name;
    } else if(this.dialogConfig.data.dataStore.address) {
      this.concessionaireStore = this.dialogConfig.data.dataStore;
      this.nameStored = this.concessionaireStore.name;
    } else {
      this.ownerStore = this.dialogConfig.data.dataStore;
      this.nameStored = this.ownerStore.name;
    }
  }

  onSaveName() {
    if(!this.nameStored.length) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: 'No es posible dejar al usuario sin un nombre',
        closable: false
      });
      return ;
    }

    if(this.passengerStore) {

      const editRequestBody: EditUserRequestBody = {
        userId: this.passengerStore.objectId,
        newData: this.nameStored,
        dataToChange: DATA_TO_CHANGE.NAME
      }

      this.isSubmitting = true;
      this.passengersService.editPassengerById(editRequestBody).then(passengerResponse => {
        this.dialogRef.close(passengerResponse);
        this.isSubmitting = false;
        this.showSuccessMessage();
        return ;
      })
      .catch(error => {
        this.isSubmitting = false;
        console.error('[ Passenger-Profile ] Could not save name: ', error);

        let message = error.message || 'Algo salió mal. Intenta de nuevo.';
        this.messageService.add({
          key: 'app-main-toast',
          severity: 'error',
          summary: 'Inválido',
          detail: message,
          closable: false
        });
        return ;
      });
    }

    if(this.driverStore) {

      const editRequestBody: EditUserRequestBody = {
        userId: this.driverStore.objectId,
        newData: this.nameStored,
        dataToChange: DATA_TO_CHANGE.NAME
      }

      this.isSubmitting = true;
      this.driversService.editDriverById(editRequestBody).then(driverResponse => {
        this.dialogRef.close(driverResponse);
        this.isSubmitting = false;
        this.showSuccessMessage();
        return ;
      })
      .catch(error => {
        this.isSubmitting = false;
        console.error('[ Driver-Profile ] Could not save name: ', error);

        let message = error.message || 'Algo salió mal. Intenta de nuevo.';
        this.messageService.add({
          key: 'app-main-toast',
          severity: 'error',
          summary: 'Inválido',
          detail: message,
          closable: false
        });
        return ;
      });
    }

    if(this.concessionaireStore) {
      const editRequestBody: EditUserRequestBody = {
        userId: this.concessionaireStore.objectId,
        newData: this.nameStored,
        dataToChange: DATA_TO_CHANGE.NAME
      }

      this.isSubmitting = true;
      this.concessionairesService.editConcessionaireById(editRequestBody).then(concessionaireResponse => {
        this.dialogRef.close(concessionaireResponse);
        this.isSubmitting = false;
        this.showSuccessMessage();
        return ;
      })
      .catch(error => {
        this.isSubmitting = false;
        console.error('[ Concessionaire-Detail ] Could not save name: ', error);

        let message = error.message || 'Algo salió mal. Intenta de nuevo.';
        this.messageService.add({
          key: 'app-main-toast',
          severity: 'error',
          summary: 'Inválido',
          detail: message,
          closable: false
        });
        return ;
      });
    }

    if(this.ownerStore) {

      const editRequestBody: EditUserRequestBody = {
        userId: this.ownerStore.objectId,
        newData: this.nameStored,
        dataToChange: DATA_TO_CHANGE.NAME
      }

      this.isSubmitting = true;
      this.ownersService.editOwnerById(editRequestBody).then(ownerResponse => {
        this.dialogRef.close(ownerResponse);
        this.isSubmitting = false;
        this.showSuccessMessage();
        return ;
      })
      .catch(error => {
        this.isSubmitting = false;
        console.error('[ Owner-Detail ] Could not save name: ', error);

        let message = error.message || 'Algo salió mal. Intenta de nuevo.';
        this.messageService.add({
          key: 'app-main-toast',
          severity: 'error',
          summary: 'Inválido',
          detail: message,
          closable: false
        });
        return ;
      });
    }
  }

  onCancel() {
    if(this.passengerStore) {
      this.dialogRef.close(this.passengerStore);
    }
    if(this.passengerStore) {
      this.dialogRef.close(this.driverStore);
    }
    if(this.concessionaireStore) {
      this.dialogRef.close(this.concessionaireStore);
    }
    this.dialogRef.close(this.ownerStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
