import { Injectable } from '@angular/core';
import { EditUserRequestBody, TableDefaults, TableRequestBody } from 'src/app/models/local';
import { TaxiOwner } from 'src/app/models/remote';

@Injectable({
  providedIn: 'root'
})
export class OwnersService {

  constructor() {
  }

  public createOwner(requestBody: TaxiOwner): Promise<TaxiOwner> {
    const OwnerStore = Backendless.Data.of('TaxiOwners');

    const owner = {
      name: requestBody.name,
      mobilePhoneNumber: requestBody.mobilePhoneNumber
    }

    return OwnerStore.save(owner)
  }

  public getOwnersForManage(tableRequestBody: TableRequestBody = {
    pageOffset: 0,
    pageSize: TableDefaults.PageSize
  }): Promise<{ owners: TaxiOwner[], totalRows: number }> {
    return Backendless.CustomServices.invoke('AdminService', 'getOwnersForManage', tableRequestBody);
  }

  public getOwnerById(ownerId: string): Promise<TaxiOwner> {
    return Backendless.CustomServices.invoke('AdminService', 'getOwnerById', ownerId);
  }

  public editOwnerById(editOwnerRequestBody: EditUserRequestBody): Promise<TaxiOwner> {
    return Backendless.CustomServices.invoke('AdminService', 'editOwnerById', editOwnerRequestBody)
  }

  public searchOwnersForAssignation(keyword: string): Promise<TaxiOwner[]> {
    return Backendless.CustomServices.invoke('AdminService', 'searchOwnersForAssignation', {keyword});
  }
}
