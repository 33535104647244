import {FormGroup} from '@angular/forms';

export default class Utilities {
  static splitArrayIntoChunks(array: any[], chunkSize: number): any[][] {
    const chunks = [];
    let i = 0;
    const n = array.length;

    while (i < n) {
      chunks.push(array.slice(i, i += chunkSize));
    }

    return chunks;
  }

  static isEmailAddress(email: string): boolean {
    // RegEx obtained from https://emailregex.com/ based on FRC 5322.
    const regexString = `(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"`
      + `(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")`
      + `@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?`
      + `|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]`
      + `:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])`;
    const regex = new RegExp(regexString);
    return regex.test(email);
  }

  static getWhatsAppLink(phoneNumber: string, countryCode: string = '521'): string {
    return `https://wa.me/${countryCode}${phoneNumber}`;
  }

  static blobToFile = (blob: Blob, fileName: string): File => {
    return new File([blob], fileName);
  };

  static findFormInvalidMessage(formGroup: FormGroup, keysToCheck: { key: string, message: string }[]): string {
    formGroup.markAllAsTouched();
    for (let i = 0; i < keysToCheck.length; i++) {
      const formEl = formGroup.get(keysToCheck[i].key);
      if (formEl.invalid) {
        return keysToCheck[i].message;
      }
    }
  }

  // TODO: If not a Backendless project, delete
  static getTranslatedBackendlessError(error) {
    error.message = BackendlessMessageCatalog[error.code] || error.message;
    return error;
  }

  static getInternationalPhoneNumber(phoneNumber: string, country: string = 'MX') {
    switch (country) {
      case 'MX':
        return `+521${phoneNumber.trim()}`;
      default:
        return phoneNumber;
    }
  }
}

// TODO: If not a Backendless project, delete
const BackendlessMessageCatalog = {
  1000: 'Registro no encontrado.',
  1155: 'El registro ya existe.',
  3003: 'Correo electrónico y/o contraseña inválidos.',
  3033: 'El correo ya está registrado con otra cuenta.',
  3044: 'Has iniciado sesión en muchos dispositivos. Cierra sesión en alguno primero.',
  21211: 'Número telefónico es inválido.'
};
