<section>
  <div class="p-float-label p-m-4">
    Dueño:
    <p-autoComplete
        *ngIf="!owner"
        class="iks-fullWidth"
        [minLength]="3"
        [suggestions]="ownerSuggestions"
        (completeMethod)="searchOwner($event)"
        (onSelect)="selectOwner($event)">
        <ng-template let-ownerItem pTemplate="item">
          <div class="p-grid">
            <div class="p-col-10">
              <div>{{ownerItem.name}}</div>
              <div>Teléfono: {{ownerItem.mobilePhoneNumber}}</div>
            </div>
          </div>
        </ng-template>
    </p-autoComplete>

    <div *ngIf="owner" class="p-grid">
      <div class="p-col-9">
        <div class="iks-selectionBox__inner">
          <div>{{owner.name}}</div>
          <div>Teléfono: {{owner.mobilePhoneNumber}}</div>
          <button pButton
                  label="Cambiar" class="ui-button-secondary p-mt-2"
                  (click)="unselectOwner()"></button>
        </div>
      </div>
    </div>
  </div>

  <p-progressBar *ngIf="isSubmitting" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
  </p-progressBar>

  <div class="p-text-right">
    <button pButton icon="pi pi-check" (click)="onSaveOwner()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>
