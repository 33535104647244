<section>
  <div class="p-float-label p-m-4 p-grid">
    <span class="p-mb-2 p-col-3">Marca: </span><input type="text" pInputText class="p-mb-2 p-col-9" [(ngModel)]="brandStored">
    <span class="p-mb-2 p-col-3">Línea: </span><input type="text" pInputText class="p-mb-2 p-col-9" [(ngModel)]="modelStored">
    <span class="p-mb-2 p-col-3">Modelo: </span><input type="number" pInputText class="p-mb-2 p-col-9" [(ngModel)]="yearStored">
    <span class="p-col-3">Placas: </span><input type="text" pInputText class="p-col-9" [(ngModel)]="plateStored">
  </div>

  <p-progressBar *ngIf="isSubmitting" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
  </p-progressBar>

  <div class="p-text-right">
    <button pButton icon="pi pi-check" (click)="onSaveData()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>

