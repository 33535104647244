import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Driver, Taxi } from '../models/remote';
import { USER_TYPE } from '../models/local';
import { DriversService } from '../services/data/drivers.service';
import { TaxisService } from '../services/data/taxis.service';

@Component({
  selector: 'app-change-picture-dialog',
  templateUrl: './change-picture-dialog.component.html',
  styleUrls: ['./change-picture-dialog.component.scss']
})
export class ChangePictureDialogComponent implements OnInit {

  pictureFile: File;
  pictureStored: string;

  isLoading: Boolean = false;
  isSubmitting: Boolean = false;

  driverStore: Driver;
  taxiStore: Taxi;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private messageService: MessageService,
              private driversService: DriversService,
              private taxisService: TaxisService) {
  }

  ngOnInit(): void {
    if(this.dialogConfig.data.dataStore.type === USER_TYPE.DRIVER) {
      this.driverStore = this.dialogConfig.data.dataStore;
      this.pictureStored = this.driverStore.driverProfile.picture;
    } else {
      this.taxiStore = this.dialogConfig.data.dataStore;
      this.pictureStored = this.taxiStore.picture;
    }
    console.log(this.pictureStored)
  }

  onPictureUpload($event: any) {
    this.pictureFile = $event.files[0];
  }

  onRemovePicture($event: any) {
    this.pictureFile = null;
  }

  onSavePicture() {
    if(!this.pictureFile) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: 'No se ha seleccionado una imagen',
        closable: false
      });
      return ;
    }

    if(this.driverStore) {

      this.isSubmitting = true;
      this.driversService.editDriverPicture(this.driverStore, this.pictureFile).then(driverResponse => {
        this.driverStore = driverResponse;
        this.isSubmitting = false;
        this.showSuccessMessage();
        this.dialogRef.close(driverResponse);
        return ;
      })
      .catch(error => {
        this.isSubmitting = false;
        console.error('[ Driver-Profile ] Could not save picture: ', error);

        let message = error.message || 'Algo salió mal. Intenta de nuevo.';
        this.messageService.add({
          key: 'app-main-toast',
          severity: 'error',
          summary: 'Inválido',
          detail: message,
          closable: false
        });
        return ;
      });
    }

    this.isSubmitting = true;
    this.taxisService.editTaxiPicture(this.taxiStore.objectId, this.pictureFile).then(taxiResponse => {
      this.taxiStore = taxiResponse;
      this.isSubmitting = false;
      this.showSuccessMessage();
      this.dialogRef.close(taxiResponse);
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.log('[ Taxi-Detail ] Could not save picture: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });
  }

  onCancel() {
    if(this.driverStore) {
      this.dialogRef.close(this.driverStore);
    }
    this.dialogRef.close(this.taxiStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
