import { Injectable } from '@angular/core';
import  Backendless  from 'backendless';
import { EditUserRequestBody, TableDefaults, TableRequestBody } from 'src/app/models/local';
import { Passenger } from 'src/app/models/remote';

@Injectable({
  providedIn: 'root'
})
export class PassengersService {

  constructor() {
  }

  public getPassengersForManage(tableRequestBody: TableRequestBody = {
    pageOffset: 0,
    pageSize: TableDefaults.PageSize
  }): Promise<{ passengers: Passenger[], totalRows: number }> {
    return Backendless.CustomServices.invoke('AdminService', 'getPassengersForManage', tableRequestBody);
  }

  public getPassengerById(passengerId: string): Promise<Passenger> {
    return Backendless.CustomServices.invoke('AdminService', 'getPassengerById', passengerId);
  }

  public editPassengerById(editDriverRequestBody: EditUserRequestBody): Promise<Passenger> {
    return Backendless.CustomServices.invoke('AdminService', 'editPassengerById', editDriverRequestBody)
  }
}
