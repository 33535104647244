import { Injectable } from '@angular/core';
import { EditUserRequestBody, TableDefaults, TableRequestBody } from 'src/app/models/local';
import { TaxiConcessionaire } from 'src/app/models/remote';

@Injectable({
  providedIn: 'root'
})
export class ConcessionairesService {

  constructor() {
  }

  public createConcessionaire(requestBody: TaxiConcessionaire): Promise<TaxiConcessionaire> {
    const ConcessionaireStore = Backendless.Data.of('TaxiConcessionaires');

    const concessionaire = {
      name: requestBody.name,
      mobilePhoneNumber: requestBody.mobilePhoneNumber,
      address: requestBody.address
    }

    return ConcessionaireStore.save(concessionaire)
  }

  public getConcessionairesForManage(tableRequestBody: TableRequestBody = {
    pageOffset: 0,
    pageSize: TableDefaults.PageSize
  }): Promise<{ concessionaires: TaxiConcessionaire[], totalRows: number }> {
    return Backendless.CustomServices.invoke('AdminService', 'getConcessionairesForManage', tableRequestBody);
  }

  public getConcessionaireById(ownerId: string): Promise<TaxiConcessionaire> {
    return Backendless.CustomServices.invoke('AdminService', 'getConcessionaireById', ownerId);
  }

  public editConcessionaireById(editOwnerRequestBody: EditUserRequestBody): Promise<TaxiConcessionaire> {
    return Backendless.CustomServices.invoke('AdminService', 'editConcessionaireById', editOwnerRequestBody)
  }

  public searchConcessionairesForAssignation(keyword: string): Promise<TaxiConcessionaire[]> {
    return Backendless.CustomServices.invoke('AdminService', 'searchConcessionairesForAssignation', {keyword});
  }
}
