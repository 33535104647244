import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { TaxisService } from '../services/data/taxis.service';
import { Taxi } from '../models/remote';

@Component({
  selector: 'app-change-insurance-file-dialog',
  templateUrl: './change-insurance-file-dialog.component.html',
  styleUrls: ['./change-insurance-file-dialog.component.scss']
})
export class ChangeInsuranceFileDialogComponent implements OnInit {

  file: File;
  insurenceFileStored: string;

  isLoading: Boolean = false;
  isSubmitting: Boolean = false;

  taxiStore: Taxi;

  constructor(private dialogConfig: DynamicDialogConfig,
              private dialogRef: DynamicDialogRef,
              private messageService: MessageService,
              private taxisService: TaxisService) {
  }

  ngOnInit(): void {
    this.taxiStore = this.dialogConfig.data.dataStore;
    this.insurenceFileStored = this.taxiStore.insuranceFile;
  }

  onFileUpload($event: any) {
    this.file = $event.files[0];
  }

  onRemoveFile($event: any) {
    this.file = null;
  }

  onSaveFile() {
    if (!this.file) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: 'No se ha seleccionado un archivo',
        closable: false
      });
      return ;
    }

    this.isSubmitting = true;
    this.taxisService.editTaxiInsuranceFile(this.taxiStore.objectId, this.file).then(taxiResponse => {
      this.taxiStore = taxiResponse;
      this.isSubmitting = false;
      this.showSuccessMessage();
      this.dialogRef.close(taxiResponse);
      return ;
    })
    .catch(error => {
      this.isSubmitting = false;
      console.error('[ Taxi-Detail ] Could not save file: ', error);

      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
      return ;
    });

  }

  onCancel() {
    this.dialogRef.close(this.taxiStore);
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'Los datos han sido guardados con éxito',
      closable: false
    });
  }

}
