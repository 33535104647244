<section class="p-m-4">
  <form [formGroup]="form">

    <div class="p-grid">

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-user"></i></span>
          <input type="text" class="iks-fullWidth" formControlName="name"
                placeholder="Nombre"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.name.touched && !form.controls.name.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica el nombre"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        Foto de conductor:
        <p-fileUpload accept="image/*"
                            chooseLabel="Elegir Imagen"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onSelect)="onPictureFileUpload($event)"
                            (onRemove)="onRemovePicture($event)"></p-fileUpload>
      </div>

      <div class="p-col-12">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-envelope"></i></span>
          <input type="email" class="iks-fullWidth" formControlName="email"
                placeholder="Correo Electrónico" inputmode="email"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.email.touched && !form.controls.email.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica el correo"></p-message>
      </div>

      <div class="p-col-12">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-directions"></i></span>
          <input type="text" class="iks-fullWidth" formControlName="address"
                placeholder="Dirección"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.address.touched && !form.controls.address.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica la dirección"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup p-mt-4">
          <span class="ui-inputgroup-addon"><i class="pi pi-id-card"></i></span>
          <input type="text" class="iks-fullWidth" formControlName="curp"
                placeholder="Curp"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.curp.touched && !form.controls.curp.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica el curp"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        <p class="p-mb-1">Vigencia de licencia:</p>
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-calendar"></i></span>
          <input type="date" class="iks-fullWidth" formControlName="licenseValidity"
                placeholder="Vigencia de licencia"
                min="{{today | date:'yyyy-MM-dd'}}"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.licenseValidity.touched && !form.controls.licenseValidity.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica la vigencia de la licencia"></p-message>
      </div>

      <div class="p-col-12 p-md-6">
        Curp (Archivo):
        <p-fileUpload accept="image/*,.pdf"
                            chooseLabel="Elegir Archivo"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onSelect)="onCurpFileUpload($event)"
                            (onRemove)="onRemoveCurp($event)"></p-fileUpload>
      </div>

      <div class="p-col-12 p-md-6">
        Licencia (Archivo):
        <p-fileUpload accept="image/*,.pdf"
                            chooseLabel="Elegir Archivo"
                            [showUploadButton]="false"
                            [showCancelButton]="false"
                            (onSelect)="onLicenseFileUpload($event)"
                            (onRemove)="onRemoveLicense($event)"></p-fileUpload>
      </div>

      <div class="p-col-12 p-md-6">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-mobile"></i></span>
          <input type="tel" class="iks-fullWidth"
                formControlName="mobilePhoneNumber"
                placeholder="Teléfono Celular"
                [readOnly]="isSubmitting" inputmode="tel" pInputText>
        </div>
        <p-message *ngIf="form.controls.mobilePhoneNumber.touched && !form.controls.mobilePhoneNumber.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica el teléfono celular"></p-message>
      </div>

      <div class="p-col-12">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-lock"></i></span>
          <input type="password" class="iks-fullWidth" formControlName="password"
                placeholder="Contraseña"
                [readOnly]="isSubmitting" pInputText>
        </div>
        <p-message *ngIf="form.controls.password.touched && !form.controls.password.valid"
                       class="iks-fullWidth p-mt-2"
                       severity="warn" text="Verifica el número telefónico"></p-message>
      </div>

    </div>
  </form>

  <div class="p-text-right p-mt-4">
    <p-progressBar *ngIf="isSubmitting" mode="indeterminate" [style]="{height: '0.5em', marginTop: '10px'}">
    </p-progressBar>
    <button pButton icon="pi pi-check" (click)="onSaveDriver()" class="p-mr-2"
            label="Guardar" [disabled]="isSubmitting"></button>
    <button pButton icon="pi pi-times" (click)="onCancel()"
            label="Cancelar" [disabled]="isSubmitting" class="ui-button-secondary"></button>
  </div>
</section>
