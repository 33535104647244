import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {environment} from 'src/environments/environment';
import { ChangeNameDialogComponent } from './change-name-dialog/change-name-dialog.component';
import { ChangeNumberDialogComponent } from './change-number-dialog/change-number-dialog.component';
import { ChangePictureDialogComponent } from './change-picture-dialog/change-picture-dialog.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { FileUploadModule } from 'primeng/fileupload';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ReactiveFormsModule } from '@angular/forms';
import {MessageModule} from 'primeng/message';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';


import Backendless from 'backendless';
import { ChangeTaxiDataDialogComponent } from './change-taxi-data-dialog/change-taxi-data-dialog.component';
import { ChangeTaxiDriverDialogComponent } from './change-taxi-driver-dialog/change-taxi-driver-dialog.component';
import { ChangeTaxiOwnerDialogComponent } from './change-taxi-owner-dialog/change-taxi-owner-dialog.component';
import { CreateDriverDialogComponent } from './create-driver-dialog/create-driver-dialog.component';
import { CreateOwnerDialogComponent } from './create-owner-dialog/create-owner-dialog.component';
import { ChangeCurpDialogComponent } from './change-curp-dialog/change-curp-dialog.component';
import { ChangeDateDialogComponent } from './change-date-dialog/change-date-dialog.component';
import { ChangeFileDialogComponent } from './change-file-dialog/change-file-dialog.component';
import { CreateTaxiDialogComponent } from './create-taxi-dialog/create-taxi-dialog.component';
import { ChangeConcessionNumberDialogComponent } from './change-concession-number-dialog/change-concession-number-dialog.component';
import { ChangeInsuranceCompanyDialogComponent } from './change-insurance-company-dialog/change-insurance-company-dialog.component';
import { ChangeInsuranceValidityDialogComponent } from './change-insurance-validity-dialog/change-insurance-validity-dialog.component';
import { ChangePolicyNumberDialogComponent } from './change-policy-number-dialog/change-policy-number-dialog.component';
import { ChangeAddressDialogComponent } from './change-address-dialog/change-address-dialog.component';
import { CreateConcessionaireDialogComponent } from './create-concessionaire-dialog/create-concessionaire-dialog.component';
import { ChangeInsuranceFileDialogComponent } from './change-insurance-file-dialog/change-insurance-file-dialog.component';
import { ChangeTaxiServicesDialogComponent } from './change-taxi-services-dialog/change-taxi-services-dialog.component';
import { ChangeTaxiConcessionaireDialogComponent } from './change-taxi-concessionaire-dialog/change-taxi-concessionaire-dialog.component';
import { ChangeTaxiImeiDialogComponent } from './change-taxi-imei-dialog/change-taxi-imei-dialog.component';

Backendless.initApp(environment.backendless.APP_ID, environment.backendless.API_KEY);

@NgModule({
  declarations: [
    AppComponent,
    ChangeNameDialogComponent,
    ChangeNumberDialogComponent,
    ChangePictureDialogComponent,
    ChangeTaxiDataDialogComponent,
    ChangeTaxiDriverDialogComponent,
    ChangeTaxiOwnerDialogComponent,
    CreateDriverDialogComponent,
    CreateOwnerDialogComponent,
    ChangeCurpDialogComponent,
    ChangeDateDialogComponent,
    ChangeFileDialogComponent,
    CreateTaxiDialogComponent,
    ChangeConcessionNumberDialogComponent,
    ChangeInsuranceCompanyDialogComponent,
    ChangeInsuranceValidityDialogComponent,
    ChangePolicyNumberDialogComponent,
    ChangeAddressDialogComponent,
    CreateConcessionaireDialogComponent,
    ChangeInsuranceFileDialogComponent,
    ChangeTaxiServicesDialogComponent,
    ChangeTaxiConcessionaireDialogComponent,
    ChangeTaxiImeiDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    ButtonModule,
    ProgressBarModule,
    FileUploadModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    MessageModule
  ],
  providers: [
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
