import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import Utilities from '../helpers/utilities';
import { RegisterDriverRequestBody } from '../models/local';
import { DriversService } from '../services/data/drivers.service';

@Component({
  selector: 'app-create-driver-dialog',
  templateUrl: './create-driver-dialog.component.html',
  styleUrls: ['./create-driver-dialog.component.scss']
})
export class CreateDriverDialogComponent implements OnInit {

  form: FormGroup;
  isSubmitting: boolean = false;
  today = new Date();

  pictureFile: File;
  curpFile: File;
  licenseFile: File;

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService,
              private dialogRef: DynamicDialogRef,
              private driversService: DriversService) {
  }

  ngOnInit(): void {

    this.today.setHours(0,0,0,0);
    this.form = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      address: new FormControl('', Validators.required),
      curp: new FormControl('', Validators.required),
      licenseValidity: new FormControl('', Validators.required),
      mobilePhoneNumber: new FormControl('', Validators.required),

      password: new FormControl('', Validators.required)
    });
  }

  private checkFormValidity(): boolean {

    const keysToCheck = [
      {key: 'name', message: 'Verifica el nombre'},
      {key: 'email', message: 'Verifica el correo electrónico'},
      {key: 'address', message: 'Verifica la dirección'},
      {key: 'curp', message: 'Verifica el curp'},
      {key: 'licenseValidity', message: 'Verifica la vigencia de la licencia'},
      {key: 'mobilePhoneNumber', message: 'Verifica el teléfono celular'},
      {key: 'password', message: 'Verifica la contraseña'}
    ];

    const message = Utilities.findFormInvalidMessage(this.form, keysToCheck);

    if (message) {
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'warn',
        detail: message,
        closable: false
      });
      return false;
    }

    return !message;
  }

  private checkSpecificValidations(): boolean {
    if(!this.pictureFile) {
      this.showInvaidError('No es posible dejar al conductor sin foto')
      return false;
    }
    if(!this.curpFile) {
      this.showInvaidError('No es posible dejar al conductor sin curp (archivo)')
      return false;
    }
    if(!this.licenseFile) {
      this.showInvaidError('No es posible dejar al conductor sin licencia (archivo)')
      return false;
    }

    const formValue = this.form.value
    if(formValue.curp.length !== 18) {
      this.showInvaidError('Curp incorrecto, debe ser de 18 caracteres')
      return false;
    }
    if(formValue.mobilePhoneNumber.length !== 10) {
      this.showInvaidError('Número telefónico incorrecto, debe ser de 10 números')
      return false;
    }
    let date: Date = new Date(formValue.licenseValidity);
    date.setMinutes( date.getMinutes() + date.getTimezoneOffset() );

    if(date.getTime() < this.today.getTime() || !date.getTime()) {
      this.showInvaidError('La fecha de vigencia de licencia no es válida')
      return false;
    }
    return true;
  }

  onPictureFileUpload($event: any) {
    this.pictureFile = $event.files[0];
  }

  onRemovePicture($event: any) {
    this.pictureFile = null;
  }

  onCurpFileUpload($event: any) {
    this.curpFile = $event.files[0];
  }

  onRemoveCurp($event: any) {
    this.curpFile = null;
  }

  onLicenseFileUpload($event: any) {
    this.licenseFile = $event.files[0];
  }

  onRemoveLicense($event: any) {
    this.licenseFile = null;
  }

  onSaveDriver() {
    if (!this.checkFormValidity()) {
      return;
    }

    if (!this.checkSpecificValidations()) {
      return;
    }

    const formValue = this.form.value;
    let date: Date = new Date(formValue.licenseValidity);
    date.setMinutes( date.getMinutes() + date.getTimezoneOffset() );

    const requestBody: RegisterDriverRequestBody = {
      email: formValue.email,
      name: formValue.name,
      picture: this.pictureFile,
      address: formValue.address,
      curp: formValue.curp,
      licenseValidity: date,
      curpFile: this.curpFile,
      licenseFile: this.licenseFile,
      mobilePhoneNumber: formValue.mobilePhoneNumber,
      password: formValue.password
    };

    this.isSubmitting = true;
    this.driversService.createDriver(requestBody).then(response => {
      console.log(response);
      this.isSubmitting = false;
      this.dialogRef.close(true);
      this.showSuccessMessage();
    })
    .catch(error => {
      this.isSubmitting = false;
      console.log('[ Create-Driver ] Could not create a driver: ', error)
      this.showInvaidError(error.message)
    })
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  private showInvaidError(message: string) {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'error',
      summary: 'Inválido',
      detail: message || 'Algo salió mal. Intenta de nuevo.',
      closable: false
    });
  }

  private showSuccessMessage() {
    this.messageService.add({
      key: 'app-main-toast',
      severity: 'success',
      summary: 'Datos guardados',
      detail: 'El conductor se ha creado con éxito',
      closable: false
    });
  }

}
