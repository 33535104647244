import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import Utilities from '../helpers/utilities';
import {LoginRequestBody, ResetPasswordRequestBody} from '../models/local';
import { User } from '../models/remote';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _userToken: string;
  private _currentUser: User;

  constructor() {
  }

  public isUserAuthenticated(): Promise<boolean> {
    // Verify Backendless session token
    return Backendless.UserService.isValidLogin().then(tokenIsValid => {
      console.log('[ AuthService ] Login token validity:', tokenIsValid);
      // If token is valid, make sure it's also valid for Facebook (if applies).
      if (!tokenIsValid) {
        return this.logout().then(() => false);
      }
      return tokenIsValid;
    }).catch(error => {
      console.warn('[ AuthService ] Could not validate token ', error);
      return this.logout().then(() => false);
    });
  }

  public getUserToken(): string {
    if (!this._userToken) {
      this._userToken = Backendless.LocalCache.get('user-token');
    }
    return this._userToken;
  }

  public getCurrentUser() {
    return Backendless.UserService.getCurrentUser();
  }

  private processUserResponse(user: User): Promise<User | any> {
    if(environment.backendless.ADMIN_ROLE_NAME !== user.type){
      this.logout();
      throw {message : 'No es posible iniciar sesión con esta cuenta'};
    }
    else {
      this._userToken = user['user-token'];

      delete user['user-token'];
      this._currentUser = user;
      return Promise.resolve(user);
    }
  }

  public login(requestBody: LoginRequestBody): Promise<any> {
    return Backendless.UserService.login(requestBody.email, requestBody.password, true).then((user: User) => {
      return this.processUserResponse(user);
    }).catch(error => {
      throw Utilities.getTranslatedBackendlessError(error);
    });
  }

  public changePassword(currentPassword: string, newPassword: string): Promise<any> {
    return Backendless.UserService.getCurrentUser().then((user: User) => {
      if (!user) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        throw {message: 'Usuario no identificado. Recarga la página e inicia sesión de nuevo.'};
      }

      // Also a persistent login because previous one is dismissed
      return Backendless.UserService.logout().then(() => {
        const loginRequestBody: LoginRequestBody = {
          email: user.email,
          password: currentPassword
        };

        return this.login(loginRequestBody);
      }).then((_user: Backendless.User) => {
        _user.password = newPassword;
        return Backendless.UserService.update(_user);
      }).then(() => {
        return Backendless.UserService.logout();
      }).then(() => {
        const loginRequestBody: LoginRequestBody = {
          email: user.email,
          password: newPassword
        };

        return this.login(loginRequestBody);
      });
    });
  }

  public logout(): Promise<void> {
    // The invoker is responsible for navigating the user back to Public pages
    const logoutPromises = [Backendless.UserService.logout()];

    return Promise.all(logoutPromises).then(() => {
      this._userToken = null;
      this._currentUser = null;
    });
  }

  public resetPassword(requestBody: ResetPasswordRequestBody): Promise<any> {
    return Backendless.UserService.restorePassword(requestBody.email);
  }

  public resendConfirmationEmail(email: string): Promise<any> {
    return Backendless.UserService.resendEmailConfirmation(email);
  }

  public isAdminUser(): Promise<boolean> {

    const userObjectId = Backendless.LocalCache.get('current-user-id')

    return Backendless.Data.of('Users').findById(userObjectId).then((role: User) => {
      return role.type.includes(environment.backendless.ADMIN_ROLE_NAME);
    });
  }
}
